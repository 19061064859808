

















































import NavbarMenu from '@/components/NavbarMenu.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Component} from 'vue-property-decorator'
import AppFooter from '@/components/AppFooter.vue'
import {MetaInfo} from 'vue-meta'
import NftCollectionTable from '@/components/nfts/NftCollectionTable.vue'
import ShowAllCollectionsToggle from '@/components/ShowAllCollectionsToggle.vue'
import FilterNetwork from '@/components/filters/FilterNetwork.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import {SimpleListNftCollectionSchema} from '@/schema/resource/NftCollection/SimpleListNftCollectionSchema'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'

@Component({
  components: {
    PoweredBy,
    LastPriceUpdated,
    FilterNetwork,
    ShowAllCollectionsToggle,
    AppFooter,
    NavbarMenu,
    NftCollectionTable,
  },
})
export default class NFTCollectionView extends MixinScreenSize {
  collection = new NftCollectionCollection()
  schema = new SimpleListNftCollectionSchema()

  poweredBy = [
    {
      url: 'https://ghostmarket.io/',
      alt: 'ghostmarket icon',
      filename: 'ghostmarket-icon.svg',
    } as PoweredByItem,
  ]

  async created() {
    this.applyResources()
    await this.populateResources()
  }

  applyResources() {
    this.collection.showBoughtInOneMonth = true
    this.collection.orderBy = 'monthVolume'
    this.collection.asc = false
    this.collection.perPage = this.collection.total
  }

  async populateResources() {
    await this.$await.run('loadCollection', () => this.collection.expand())
  }

  handleFilterNetwork() {
    // TODO: Implement filter network
  }

  showAllCollectionsToggleEvent(val: boolean) {
    this.collection.showBoughtInOneMonth = !val
    this.$await.run('loadCollection', () => this.collection.queryAsExpansible())
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.nfts'),
    }
  }
}
